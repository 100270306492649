<template>
	<screen size="medium" class="linked-account">
		<template slot="body">
			<h2><inspire></inspire></h2>
			<ui-loader :loading="isLoading" />
		</template>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import table from '@/mixins/table'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import Modal from '../../ui/Modal'
import Inspire from '../../ui/Inspire'
import UiLoader from '../../ui/Loader'

export default {
	name: 'linked-account',
	components: {
		UiButton,
		UiInput,
		UiLabel,
		Screen,
		Modal,
		Inspire,
		UiLoader
	},
	mixins: [table],
	props: {
		uuid: String
	},
	data() {
		return {
			isLoading: true,
			waitingOnStripe: false,
			stripeErrors: [],
			donationSelection: 'express',
			StripeAccount: null,
			radioOptions: {
				express: 'Link Stripe Account',
				plaid: 'Link Bank Account',
				other: 'Other'
			}
		}
	},
	computed: {
		...mapState({
			stripe_details: ({ stripeActions }) => stripeActions
		})
	},
	mounted() {
		this.stripeAccountSetup()
	},
	methods: {
		...mapActions(['getCurrentUser', 'getStripeAccountDetails']),
		async stripeAccountSetup() {
			await this.getStripeAccountDetails()
			if (
				this.stripe_details.errors !== null ||
				!this.stripe_details.finished
			) {
				this.$router.push({ name: 'bank-account' })
			} else {
				await this.getCurrentUser()
				this.$router.push({ name: 'brand' })
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.linked-account {
	position: relative;
}
h2 {
	text-align: center;
}
</style>
