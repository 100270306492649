<template>
	<div>
		<div v-if="show">{{ message }}</div>
	</div>
</template>

<script>
export default {
	name: 'inspire',
	computed: {
		show() {
			return true
		},
		message() {
			let messageList = [
				'Turning pocket change into global change',
				'Make good happen',
				'Effortlessly good'
			]
			return messageList[Math.floor(Math.random() * 3)]
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/main';
@import '../../styles/variables';
</style>
